<template>
  <div>
    <el-card>
      <el-tabs v-model="activeName">
        <el-tab-pane label="General" name="general">
          <alter-form ref="alterForm" @formSubmit="formSubmit" :modifyData="modifyData" :formData="formData" :collapseLength="collapseLength"></alter-form>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
// 组件
import alterForm from '@/components/alterForm'
// 接口
import apis from '@/services'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'

export default {
  components: { alterForm },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      activeName: 'general'
    }
  },
  created () { this.init() },
  methods: {
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      const userGroup = await apis.TariffsBillings.getUserGroupSimpleList()
      this.formData[6].options = userGroup.data.user_groups
      if (this.$route.params.id) {
        const res =  await apis.CrmUsers.getCrmUsersEditPreview(this.$route.params.id)
        this.modifyData = res.data.user
      }
      this.$store.state.app.loading.close()
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = { user: obj }
      let request = null
      const array = []
      if (this.$route.params.id) {
        request = apis.CrmUsers.putCrmUsersFormData
        array.push(this.$route.params.id)
      } else {
        request = apis.CrmUsers.postCrmUsersAdd
      }
      array.push(params)
      request(...array).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess'),
            duration: 1000,
            onClose: () => { this.$router.go(-1) }
          })
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
</script>

<style lang="scss" scope>
</style>
