import i18n from '@/i18n/index'
// import constant from '@/utils/constant.js'

export default {
  formData: [{
    span: 16,
    prop: 'name',
    name: 'input',
    label: 'First Name',
    placeholder: i18n.t('First Name'),
    Object: { }
  },
  {
    span: 16,
    prop: 'last_name',
    name: 'input',
    label: 'Last Name',
    placeholder: i18n.t('Last Name'),
    Object: { }
  },
  {
    span: 16,
    prop: 'phone_number',
    name: 'input',
    label: 'Phone Number',
    placeholder: i18n.t('Phone Number'),
    Object: { }
  }, {
    span: 16,
    prop: 'email',
    name: 'input',
    label: 'Email',
    placeholder: i18n.t('Email'),
    Object: { }
  }, {
    span: 16,
    prop: 'password',
    name: 'input',
    label: 'Password',
    type: 'password',
    placeholder: i18n.t('Password'),
    Object: { }
  }, {
    span: 16,
    prop: 'password_confirmation',
    name: 'input',
    label: 'Password Confirmation',
    type: 'password',
    placeholder: i18n.t('Password Confirmation'),
    Object: { }
  }, {
    span: 16,
    prop: 'user_group_id',
    name: 'select',
    options: [],
    value: [],
    label: 'User Group',
    placeholder: i18n.t('User Group'),
    Object: {
      filterable: true,
      value: 'id'
    }
  }]
}
